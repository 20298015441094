@charset "UTF-8";

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-size: 0.8rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  line-height: initial;
}

.react-datepicker__header {
  text-align: center;
  position: relative;
}

.react-datepicker__month {
  text-align: center;
  margin-top: 4px;
}
.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__day-names .react-datepicker__day-name {
  color: #999 !important;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
}

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer;
}

.react-datepicker__day:not([aria-disabled="true"]):hover,
.react-datepicker__month-text:not([aria-disabled="true"]):hover {
  opacity: 0.7;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: #dbdffa;
  color: #000;

  &:not(.highlighted-day) {
    background-color: #ebebeb;
    color: #000;
  }
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #3747ab;
  color: #fff;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range
  ) {
  background-color: #dbdffa;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}
