#root,
body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  @extend .primaryFont;
  color: $text-primary;
  .dim {
    color: hsl(0, 0%, 53%);
  }
  .dim-m {
    color: hsl(0, 0%, 70%);
  }
  .dim-l {
    color: hsl(0, 0%, 80%);
  }
  .title-secodary {
    color: $title-secondary;
  }
  .label-primary {
    color: $label-primary;
  }
  .well-bg {
    background-color: $well-bg;
  }
  .bg-gray {
    background-color: #ececec;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-13-sm-12, // on small screen changes to 12
  .font-13 {
    font-size: 13px;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-15 {
    font-size: 15px;
  }
  .font-16 {
    font-size: 16px;
  }
  .font-17 {
    font-size: 17px !important;
  }
  .font-18 {
    font-size: 18px;
  }
  .font-19 {
    font-size: 19px;
  }
  .font-20 {
    font-size: 20px;
  }
  .font-22 {
    font-size: 22px;
  }
  .font-24 {
    font-size: 24px;
  }
  .font-25 {
    font-size: 25px;
  }
  .font-28 {
    font-size: 28px;
  }
  .font-32 {
    font-size: 32px;
  }
  .h1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
  }
  .h3 {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .max-width-500 {
    max-width: 500px;
  }
  .para-primary {
    color: $para-primary;
  }
  .title-primary-color {
    color: $title-primary;
  }
  .word-break {
    word-break: break-all;
  }
  .message-top {
    top: 0px !important;
    padding-top: 0 !important;
  }
  .border-radius-circle {
    border-radius: 50%;
  }
  .page-top-header {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: $title-primary;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @mixin date-picker-p($color, $font-weight, $line-height, $border-radius) {
    @extend .secondaryFont;
    box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
    background-color: #ffffff;
    border-radius: $border-radius;
    height: 55px;
    font-size: 16px;
    font-weight: $font-weight;
    line-height: $line-height;
    border: 2px solid #e8eaf7;
    color: $color;
    width: 100%;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      outline: none;
    }
  }
  .lft-arrow-green-dark {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    cursor: pointer;
    @extend .primaryFont;
    color: $primary-color;
    padding-left: 40px;
    &:before {
      content: "";
      background: url("../images/back_arrow.svg") center center no-repeat;
      height: 16px;
      width: 24px;
      position: absolute;
      left: 0;
      top: 3px;
      bottom: 0;
    }
  }
  .lft-arrow-green-dark {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    cursor: pointer;
    @extend .primaryFont;
    color: $green-dark;
    padding-left: 40px;
    &:before {
      content: "";
      background: url("../images/back_arrow_green_dark.svg") center center no-repeat;
      height: 16px;
      width: 24px;
      position: absolute;
      left: 0;
      top: 3px;
      bottom: 0;
    }
  }
  a.multi-line-link {
    &:hover {
      text-decoration: underline !important;
      color: blue;
    }
    &:after {
      content: "";
      height: 0;
      color: #412894;
    }
  }
  .drop-open {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 5px;
    width: 10px;
    height: 11px;
    object-fit: contain;
    background: url("../images/green-down-arrow.svg") center center no-repeat;
    transform: rotateX(-180deg);
  }
  .drop-closed {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 5px;
    width: 10px;
    height: 11px;
    object-fit: contain;
    background: url("../images/green-down-arrow.svg") center center no-repeat;
  }
  .delete-button {
    user-select: none;
    cursor: pointer;
    color: red;
  }
  div.link-button {
    text-decoration: none;
  }
  a,
  div.link-button {
    user-select: none;
    color: $primary-color;
    position: relative;
    @include transition;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
      @include transition;
      &:after {
        opacity: 1;
        @include transition;
      }
    }
    &:focus {
      color: $primary-dark;
      &:after {
        opacity: 0;
        @include transition;
      }
    }
    &:not(.no-underline):after {
      content: "";
      opacity: 0;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 1px;
      background: $primary-hover;
      @include transition;
    }
    &.text-style-none:hover:after {
      display: none;
    }
    &.bold {
      font-weight: 500;
    }
    &.text-danger {
      font-weight: 600;
    }
    &.arrow-link {
      position: relative;
      padding-right: 40px;
      @extend .primaryFont;
      color: $primary-color;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.13px;
      line-height: 29px;
      &:before {
        content: "";
        background: url("../images/dropdown_arrow.svg") right center no-repeat;
        height: 16px;
        width: 24px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        @extend .transition;
        transform-style: preserve-3d;
      }
      &.opened {
        &::before {
          transform: rotateX(-180deg);
        }
      }
    }
    &.arrow-link-dark {
      position: relative;
      padding-right: 40px;
      @extend .primaryFont;
      color: #007a4c;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.13px;
      line-height: 29px;
      text-decoration: underline;
      &:hover,
      &::after {
        opacity: 0.8;
        background: transparent;
      }
      &:before {
        content: "";
        background: url("../images/dropdown_arrow_dark.svg") right center no-repeat;
        height: 16px;
        width: 24px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        @extend .transition;
        transform-style: preserve-3d;
        @media screen and (max-width: 991px) {
          background: url("../images/dropdown_arrow_dark_small.svg") right center no-repeat;
          left: 100px;
        }
      }
      &.opened {
        &::before {
          transform: rotateX(-180deg);
        }
      }
    }
    &.lft-arrow-green-dark {
      // phase this nested class out, shouldn't have to be anchor element
      position: relative;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 26px;
      cursor: pointer;
      @extend .primaryFont;
      color: $primary-color;
      padding-left: 40px;
      &:before {
        content: "";
        background: url("../images/back_arrow.svg") center center no-repeat;
        height: 16px;
        width: 24px;
        position: absolute;
        left: 0;
        top: 3px;
        bottom: 0;
      }
    }
    &.lft-arrow-green-dark {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 26px;
      cursor: pointer;
      @extend .primaryFont;
      color: $green-dark;
      padding-left: 40px;
      &:before {
        content: "";
        background: url("../images/back_arrow_green_dark.svg") center center no-repeat;
        height: 16px;
        width: 24px;
        position: absolute;
        left: 0;
        top: 3px;
        bottom: 0;
      }
    }
    &.rt-arrow {
      padding-left: 0px;
      padding-right: 40px;
      &:before {
        content: "";
        transform: rotate(180deg);
        right: 0;
        left: auto;
      }
    }
  }
  div.link-button {
    display: inline;
    cursor: pointer;
    // &:hover{
    //     &:after {
    //         opacity: 0;
    //     }
    // }
  }
  .info-icon {
    background: url("../images/info.svg") center top no-repeat;
    background-size: 100%;
    height: 26px;
    width: 26px;
    cursor: pointer;
  }

  .info-icon-green {
    background: url("../images/info_green.svg") center top no-repeat;
    background-size: 100%;
    height: 13px;
    width: 13px;
    cursor: pointer;
    margin-left: 8px;
  }
  .exclamatory-blue-icon {
    background: url("../images/exclamatory-blue.svg") center top no-repeat;
    background-size: 100%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    display: inline-block;
    margin: -2px 3px -7px 0px;
  }
  .wrap-modal-px-0 {
    &.modal {
      .modal-content {
        .modal-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .notesModal {
    &.modal {
      .modal-content {
        .modal-header {
          border-bottom: 1px solid #757575;
          color: #412894;
          font-size: 18px;
          line-height: 26px;
          padding: 30px 30px 15px 30px;
          font-weight: normal;
        }
        .buttonWrapNote {
          border-bottom: 1px solid #757575;
          width: 100%;
          padding-bottom: 30px;
          .shiftBtn {
            font-size: 20px;
            color: #757575;
            padding-right: 30px;
            img {
              margin-right: 10px;
            }
          }
        }
        .addNote {
          color: $primary-color;
          background: transparent;
          border-color: transparent;
          display: inline-block;
          width: auto;
          margin: 15px 15px 15px 0px;
        }
        .noteWrap {
          margin-top: 10px;
          align-items: center;
          img {
            margin-right: 8px;
            vertical-align: text-bottom;
          }
          label {
            color: #757575;
            font-size: 22px;
            line-height: 33px;
            margin-right: 13px;
            margin-bottom: 0;
          }
          span {
            display: inline-block;
            word-break: break-word;
          }
        }
        .modal-body {
          padding-bottom: 20px;
          flex-direction: column;
          form {
            margin-top: 30px;
          }
        }
      }
    }
  }
  img {
    max-width: 100%;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .btn,
  a.btn,
  button.btn,
  input[type="submit"] {
    color: $white;
    border-radius: 50px;
    padding: 10px 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.5px;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(134, 134, 134, 0.26);
    min-width: 220px;
    &:hover:after {
      display: none;
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 2px 5px 0 rgba(134, 134, 134, 0.26);
    }
    &.btn-internship {
      background: $title-primary;
      color: #fff;
      min-width: 116px;
      height: 45px;
    }
    &.btn-externship {
      background: $coral-light;
      min-width: 116px;
      height: 45px;
    }
    &.btn-externship-v2 {
      background: $teal-dark600;
      min-width: 116px;
      height: 45px;
    }
    &.btn-primary {
      background: $primary-color;
      &.disabled {
        opacity: 0.3;
      }
      &:focus,
      &:hover:not(.disabled) {
        background: $primary-hover;
      }
      &:not(:disabled):not(.disabled):active {
        background: $primary-dark;
      }
      &.btn-outline-primary {
        background: none;
        box-shadow: 0 0 0 2px $primary-color inset;
        border: 0;
        color: $primary-color;
        width: 100%;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
          background: transparent;
          box-shadow: inset 0 0 0 2px rgba(95, 195, 157, 0.8);
        }
        &:not(:disabled):not(.disabled):active {
          background: transparent;
          box-shadow: 0 0 0 2px $primary-dark;
        }
      }
      &.btn-outline-danger {
        background: none;
        box-shadow: 0 0 0 2px $expiredShift inset;
        border: 0;
        color: $expiredShift;
        width: 100%;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
          background: transparent;
          box-shadow: inset 0 0 0 2px $expiredShift;
        }
        &:not(:disabled):not(.disabled):active {
          background: transparent;
          box-shadow: 0 0 0 2px $expiredShift;
        }
      }
    }
    &.lg-btn {
      padding: 13px 30px;
    }
    &.md-btn {
      min-width: inherit;
      padding: 8px 24px;
      &.min-width {
        min-width: 160px;
      }
    }
    &.sm-btn {
      min-width: auto;
      padding: 7px 18px;
      line-height: 21px;
      height: 35px;
    }
    &.xs-btn {
      padding: 5px 20px;
      min-width: auto;
      letter-spacing: 1px;
      line-height: 20px;
      height: 30px;
    }
  }
  .confirm-xs-btn {
    &.pull-right {
      float: right;
    }
    .btn {
      padding: 3px 30px;
      line-height: 20px;
      min-width: inherit;
      position: relative;
      top: -3px;
      text-transform: uppercase;
    }
  }
  .btn-loader {
    position: relative;
    display: inline-block;
    &:after {
      content: " ";
      display: block;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      border: 4px solid #fff;
      border-color: #fff transparent #fff transparent;
      border-left-color: rgba(33, 35, 34, 0.07);
      border-right-color: rgba(33, 35, 34, 0.07);
      animation: btn-loader 800ms linear infinite;
    }
    .btn {
      pointer-events: none;
      cursor: auto;
    }
  }
  @keyframes btn-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .link {
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
    &.lg {
      letter-spacing: 1.13px;
      line-height: 29px;
    }
  }
  .section-padding {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .or {
    display: block;
    position: relative;
    margin: 24px 0;
    &:before {
      content: "";
      height: 1px;
      background: #b9c1ec;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 200px;
    }
    i {
      font-style: normal;
      background: #fff;
      padding: 0 6px;
      color: #535353;
      @extend .secondaryFont;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    &.white {
      i {
        background: $white;
      }
    }
    &.light-blue {
      i {
        background: #f1f2fa;
      }
    }
  }
  .card-flat {
    background: #fff;
    border: 1px solid #e8eaf7;
  }
  .default-form,
  form {
    .form-group {
      margin-bottom: 22px;
      .form-control {
        box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
        background-color: $white;
        @extend .form-element-border;
        border-radius: 2px;
        height: 55px;
        font-size: 16px;
        line-height: 22px;
        @extend .secondaryFont;
        &:disabled {
          border: 2px solid #e8eaf7;
          background-color: rgba(148, 148, 148, 0.1);
          box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
          cursor: not-allowed;
        }
      }
      textarea.form-control {
        height: auto;
        min-height: 130px;
        resize: none;
        font-size: 16px;
        line-height: 22px;
        @extend .secondaryFont;
      }
      textarea.form-control.sm {
        min-height: 52px;
      }
      textarea.form-control.lg {
        min-height: 260px;
      }
      .text-label {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1.5;
        font-weight: normal;
      }
      label {
        @extend .form-label;
        margin-bottom: 0;
      }
      .note {
        font-size: 12px;
        color: #949494;
        margin: 0;
        padding-top: 4px;
        @extend .secondaryFont;
      }
    }
    .form-group-v2 {
      label {
        @extend .form-label-v2;
      }
      .form-control {
        box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
        background-color: $white;
        border: 1px solid $light-blue;
        border-radius: 8px;
        padding: 12px 8px;
        height: 48px;
        color: #59595a;
        @extend .body-text;
        &::placeholder {
          color: #59595a;
        }
        &:disabled {
          border: 1px solid #e8eaf7;
          background-color: rgba(148, 148, 148, 0.1);
          box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
          cursor: not-allowed;
        }
      }
    }
    .form-group-small {
      label {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3747ab;
      }
      .form-control {
        box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
        background-color: $white;
        border: 1px solid $light-blue;
        border-radius: 8px;
        padding: 12px 8px;
        height: 48px;
        color: #59595a;
        @extend .body-text;
        &::placeholder {
          color: #59595a;
        }
        &:disabled {
          border: 1px solid #e8eaf7;
          background-color: rgba(148, 148, 148, 0.1);
          box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
          cursor: not-allowed;
        }
      }
    }
    .input-group {
      height: 48px;
      .form-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 8px;
        gap: 8px;
        width: 282px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #dbdffa;
        border-radius: 4px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #303031;
        ::placeholder {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #303031;
        }
      }
      .input-group-text {
        background: $primary-color url("../images/search-icon.png") center center no-repeat;
        background-size: 21px;
        width: 50px;
        border-color: $primary-color;
        cursor: pointer;
        @include transition;
        &:hover {
          @include transition;
          background-color: rgba(95, 195, 157, 0.8);
        }
        &:focus {
          @include transition;
          background-color: #55ad8c;
          outline: none;
        }
        &.clear-search {
          background: $primary-color none;
          color: #fff;
          font-weight: 600;
          width: 40px;
        }
      }
      .input-group-text-v2 {
        background: #fff url("../images/search.svg") center center no-repeat;
        width: 50px;
        border: 1px solid #dbdffa;
        border-left: none;
        position: relative;
        left: -3px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
        @include transition;
        &:focus {
          outline: none;
        }
        &.clear-search {
          background: $primary-color none;
          color: #fff;
          font-weight: 600;
          width: 40px;
        }
      }
    }
  }
  label {
    strong {
      font-weight: 600;
    }
  }
  .default-form.inline {
    .form-group {
      margin-bottom: 0;
    }
  }
  .form-control {
    &.sm-form-control {
      height: 29px;
      width: 69px;
      border: 2px solid #e8eaf7;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
    }
  }
  .message-incomplete {
    font-size: 14px;
    @extend .secondaryFont;
    animation: 200ms ease normal forwards 1 downAnimation;
    -webkit-animation: 200ms ease normal forwards 1 downAnimation;
    &::before {
      content: "*Please complete all required fields";
    }
  }
  form {
    .message {
      position: absolute;
      top: 100%;
      padding: 0;
      padding-top: 4px;
      font-size: 12px;
      @extend .secondaryFont;
      animation: 200ms ease normal forwards 1 downAnimation;
      -webkit-animation: 200ms ease normal forwards 1 downAnimation;
      &.rel-message {
        position: relative;
        top: 0;
      }
      &.label-message {
        top: 2px;
      }
    }
    .btn-container {
      .message {
        right: 0px;
        left: 0px;
        text-align: center;
      }
    }
    .form-group > .required {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 14px;
        color: #dc3545;
        font-size: 15px;
        font-weight: 300;
        @extend .secondaryFont;
        display: none;
      }
      .form-control {
        padding-right: 30px;
      }
      &.password-input {
        .message {
          background: #fff;
          right: 0;
          animation: none;
          margin-top: 20px;
          &.pass-note {
            margin-top: 0;
            animation: none;
          }
        }
        // & + .note{
        //padding-right:80px;
        // }
      }

      .signature {
        font-family: "Rouge Script", cursive;
        font-size: 24px;
      }
    }
    .form-group > .required-top24 {
      &:after {
        top: 24px;
      }
    }
    .form-group > .required-show {
      &:after {
        content: "*required";
        display: block;
      }
    }
  }
  // .terms-privacy-wrapper {
  //   padding-top: 120px;
  //   padding-bottom: 80px;
  //   p,
  //   ol li,
  //   ul li {
  //     font-size: 14px;
  //     line-height: 19px;
  //     @extend .secondaryFont;
  //     color: $text-secondary;
  //     margin-bottom: 20px;
  //     &.upperCase {
  //       text-transform: uppercase;
  //     }
  //     &.primary-head {
  //       font-weight: 700;
  //       margin-bottom: 20px;
  //     }
  //     &.secondary-head {
  //       font-weight: 600;
  //       margin-bottom: 20px;
  //     }
  //   }
  //   ol,
  //   ul {
  //     margin: 0 0 20px 20px;
  //     padding: 0 0 0 16px;
  //     li {
  //       margin: 0 0 6px 0;
  //     }
  //   }
  //   .addressWrap {
  //     margin-left: 20px;
  //   }
  // }
  .inner-page-menu {
    display: none;
  }
  .inner-container {
    overflow: hidden;
    padding: 160px 0;
    min-height: 100vh;
    position: relative;
    background: #f9f9f9;
    &.sm-top {
      padding: 76px 0px;
    }
    &.md-top {
      padding: 105px 0;
    }
    &.white-bg {
      &::after {
        display: none;
      }
    }
    &.white-bg {
      &::after {
        display: none;
      }
    }
    &.blue-bg {
      background-color: #edf0fd;
    }

    &.sm-height-after:after {
      height: 100%;
    }
    & > .container {
      position: relative;
      z-index: 1;
      &.img-prv-zindex {
        z-index: 99;
      }
    }
    &.py-md {
      padding: 90px 0 160px 0;
    }
    .well {
      z-index: 2;
      position: relative;
      &.forgot-password-box {
        max-width: 514px;
        width: 100%;
        margin: 0 auto;
        .password-label {
          color: #535353;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          display: block;
          margin: 32px 0 48px;
        }
        .form-group {
          max-width: 414px;
          width: 100%;
          margin: 0 auto;
        }
        .btn.btn-primary {
          min-width: 300px;
        }
        .verify-box {
          max-width: 324px;
          width: 100%;
          margin: 40px auto 0;
          p {
            color: #535353;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
          }
          .diff-email {
            color: #007a4c;
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 26px;
            text-align: center;
            display: block;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .hospital-ill {
      background: url("../images/ill_hospital.svg") right 68% no-repeat;
      background-size: auto 315px;
    }
    .vet-reg-ill {
      background: url("../images/ill_vet.svg") right 77% no-repeat;
      background-size: auto 390px;
    }
    .tech-reg-ill {
      background: url("../images/ill_tech.svg") right 70% no-repeat;
      background-size: auto 390px;
    }
    .student-reg-ill {
      background: url("../images/vet_student.png") right bottom no-repeat;
      background-size: auto 390px;
    }
    .form-steps-line {
      position: relative;
      height: 30px;
      width: 250px;
      margin: 0 auto 75px;
      &:before {
        content: "";
        height: 1px;
        top: 14px;
        width: 100%;
        display: block;
        background: $title-secondary-lighter;
        position: relative;
      }
      i {
        position: absolute;
        left: 0;
        top: 6px;
        height: 17px;
        width: 17px;
        border-radius: 20px;
        border: 1px solid $title-secondary-lighter;
        background: $title-secondary-lightest;
        &.current {
          background: $title-secondary-lighter;
        }
        &:nth-child(2) {
          left: 78px;
        }
        &:nth-child(3) {
          left: 155px;
        }
        &:last-child {
          left: auto;
          right: 0;
        }
      }
      &.vet-steps {
        width: 330px;
        i {
          &:nth-child(4) {
            left: 235px;
          }
        }
      }
      &.sm-steps {
        width: 170px;
      }
    }
    .aaha-logo {
      background: url("../images/aaha_logo.svg") right center no-repeat;
      padding-right: 150px;
    }
    // internship
    .create-internship {
      .head {
        color: $title-primary;
        @extend .primaryFont;
        font-weight: normal;
      }

      .date-picker {
        h4,
        .calendar-picker {
          float: left;
          clear: left;
        }
        .date-picker-wrapper {
          position: absolute;
          top: 12px;
          right: 8px;
          margin: auto;
          height: 50px;
          .DayPicker {
            margin: 0;
          }
        }
        .calendar-picker {
          height: 30px;
          width: 30px;
          background: url("../images/cal_view_ic_active.svg") center center no-repeat !important;
          margin: 0;
          cursor: pointer;
        }
        .message {
          padding: 14px 0 0 10px;
          font-size: 13px;
          float: left;
        }
      }
      .custom-check {
        position: relative;
        .message {
          top: 0;
        }
      }
    }

    .create-shift {
      .pending-alert {
        margin-bottom: 24px;
      }
      .head {
        margin-bottom: 30px;
        h3,
        h4 {
          color: $title-primary;
          @extend .primaryFont;
          font-size: 28px;
          line-height: 46px;
          font-weight: normal;
          margin: 0;
        }
        h4 {
          font-weight: 600;
        }
        .date-picker {
          position: relative;
          padding-left: 50px;
          h4,
          .calendar-picker {
            float: left;
            clear: left;
          }
          &.force-show-calendar {
            padding-left: 0;
            h4 {
              float: none;
              clear: none;
            }
            .calendar-picker {
              margin-bottom: 0.5rem;
            }
          }
          .date-picker-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            //bottom: 0;
            margin: auto;
            height: 50px; //float: left;
            .DayPicker {
              //left:20px;
              margin: 0;
            }
            &.force-show-calendar {
              position: relative;
              display: inline-block;
            }
          }
          .calendar-picker {
            height: 30px;
            width: 30px;
            background: url("../images/calendar.svg") center center no-repeat;
            margin: 10px 0 0;
            cursor: pointer;
          }
          .message {
            padding: 14px 0 0 10px;
            font-size: 13px;
            float: left;
          }
        }
      }
      .h3-header {
        color: $blue-dark;
      }
      .custom-check {
        position: relative;
      }
      .custom-check [type="radio"]:checked + label,
      .custom-check [type="radio"]:not(:checked) + label {
        font-size: 16px;
      }
      .shift-cost-info {
        position: relative;
        color: #404040;
        @extend .primaryFont;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        margin-top: 18px;
        display: grid;
        .shift-cost-info-top-container {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          .info-icon {
            margin-left: 3rem;
          }
        }
      }
      .scrollable-section {
        @media all and (min-width: 767px) {
          height: calc(100vh - 247px);
          overflow: auto;
        }
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      .sticky {
        position: fixed;
        left: 50%;
        padding: 0px 15px;
      }
    }
    .right-sidebar {
      background: $white;
      border: 1px solid #e8eaf7;
      box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.09);
      border-radius: 2px;
      padding: 12px;
      &-v2 {
        background: $white;
        border: 1px solid #e8eaf7;
        box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.09);
        border-radius: 2px;
        padding: 0;
        .section-header-v2 {
          color: $blue-dark;
          @extend .tertiaryFont;
          font-weight: 400;
          font-size: 20px;
          padding: 8px 8px 0 8px;
          line-height: 28px;
        }

        .section-footer-v2 {
          color: $blue-dark;
          @extend .tertiaryFont;
          font-weight: 400;
          font-size: 16px;
          padding: 8px 8px 0 8px;
        }
      }
      .section-title {
        color: $title-secondary;
        @extend .primaryFont;
        font-weight: normal;
        font-size: 18px;
        padding: 12px 12px 0;
        letter-spacing: 1.5px;
        line-height: 29px;
      }
      .sidebar-tab {
        color: #7b87cc;
        background: #fafbff;
        padding: 14px 0;
        display: inline-block;
        @extend .primaryFont;
        font-weight: normal;
        font-size: 17px;
        text-align: center;
        width: 50%;
        letter-spacing: 1.2px;
        line-height: 27px;
        text-decoration: none;
        &:not(active) {
          box-shadow: inset 0 -5px 8px rgba(65, 40, 148, 0.06);
        }
      }
      .sidebar-tab.active {
        color: #639fcc;
        background: #fff;
        cursor: default;
        font-weight: 600;
        letter-spacing: 0.9px;
        &.left-shadow {
          border-left: 1px solid 7b87cc;
          box-shadow: -10px 0 15px 0 rgba(65, 40, 148, 0.06);
        }
        &.right-shadow {
          border-right: 1px solid 7b87cc;
          box-shadow: 10px 0 15px 0 rgba(65, 40, 148, 0.06);
        }
      }
      ul.shift-list,
      ul.toggle-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          padding-bottom: 12px;
          .head {
            background-color: rgba(246, 247, 252, 0.8);
            span {
              color: #7b87cc;
            }
            a {
              color: #007a4c;
              @extend .primaryFont;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 26px;
              float: right;
            }
            button {
              &.xs-btn {
                float: right;
                position: relative;
                top: -3px;
              }
            }
          }
          .head,
          .head-externship {
            padding: 10px;
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: 12px;
            span {
              @extend .secondaryFont;
              font-size: 14px;
              line-height: 19px;
            }
          }
          .head-externship {
            background-color: $coral-lightest;
            span {
              color: #3747ab;
            }
          }
          .head-internship {
            background-color: rgba(246, 247, 252, 0.8);
            padding: 10px;
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: 12px;
            span {
              color: #3747ab;
              @extend .secondaryFont;
              font-size: 14px;
              line-height: 19px;
            }
            a {
              color: #007a4c;
              @extend .primaryFont;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 26px;
              float: right;
            }
            button {
              &.xs-btn {
                float: right;
                position: relative;
                top: -3px;
              }
            }
          }
          p {
            color: $text-secondary;
            margin-bottom: 12px;
            @extend .secondaryFont;
            font-size: 16px;
            line-height: 22px;
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    .hospital-contact-info {
      .blank-bg {
        height: 20px;
        width: 100%;
        background-color: rgba(246, 247, 252, 0.8);
      }
      .shift-list {
        margin-top: 20px;
        label {
          color: #5862a3;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 26px;
        }
        span {
          color: #535353;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          line-height: 22px;
          display: block;
          margin-bottom: 24px;
        }
      }
    }
    .DayPickerInput {
      width: 100%;
      position: relative;
      &:after {
        content: "";
        height: 30px;
        width: 30px;
        background: url("../images/calendar.svg") center center no-repeat;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
      }
      &.date-picker-p {
        line-height: 40px !important;
      }
      input,
      &.date-picker-p {
        @include date-picker-p($text-secondary, 400, 22px, 2px);
      }
      input,
      &.date-picker-p-v2 {
        @include date-picker-p($gray-light-400, 500, 24px, 8px);
        font-family: "Poppins", sans-serif;
      }
    }
    .date-picker-wrapper {
      position: relative;
      .DayPicker-NavBar {
        .DayPicker-NavButton--next,
        .DayPicker-NavButton--prev {
          width: 20px;
          height: 20px;
          top: 12px;
          right: 15px;
          &:focus {
            outline: none;
          }
        }
        .DayPicker-NavButton--next {
          background: url("../images/arrow_right@2x.png") center center no-repeat;
          background-size: 100%;
        }
        .DayPicker-NavButton--prev {
          background: url("../images/arrow_left@2x.png") center center no-repeat;
          background-size: 100%;
          margin-right: 30px;
        }
      }
      .calendar-picker {
        height: 30px;
        width: 30px;
        background: url("../images/calendar.svg") center center no-repeat;
        margin: 8px 0 0 20px;
        cursor: pointer;
      }
      .DayPicker {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        box-shadow: 0 2px 22px 0 rgba(45, 31, 90, 0.25);
        z-index: 99;
        margin-top: 5px;
        .DayPicker-wrapper {
          padding-bottom: 12px;
          &:focus {
            outline: none;
          }
        }
        .DayPicker-Months {
          &:focus {
            box-shadow: none;
            outline: none;
          }
          .DayPicker-Caption {
            & > div {
              font-size: 16px;
              letter-spacing: 1.33px;
              line-height: 26px;
              color: $title-secondary;
              font-weight: normal;
            }
          }
          .DayPicker-Month {
            margin: 10px 10px 0;
          }
          .DayPicker-Weekdays {
            position: relative;
            &:before {
              content: "Select one or multiple dates.";
              @extend .secondaryFont;
              color: $para-primary;
              font-size: 14px;
              line-height: 19px;
              position: absolute;
              left: 17px;
              font-weight: normal;
            }
            .DayPicker-WeekdaysRow {
              opacity: 0;
            }
          }
          .DayPicker-Body {
            .DayPicker-Day {
              padding: 8px 10px;
              font-size: 14px;
              line-height: 19px;
              @extend .secondaryFont;
              color: $text-secondary;
              &.DayPicker-Day--outside {
                color: #bebebe;
                cursor: pointer;
              }
              &.DayPicker-Day--disabled {
                color: #bebebe;
                pointer-events: none;
              }
              &.DayPicker-Day--today {
                font-weight: normal;
                position: relative;
                &:before {
                  content: "";
                  border: 1px solid #95a3ed;
                  border-radius: 100px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 30px;
                  height: 30px;
                  margin: auto;
                  pointer-events: none;
                }
              }
              &.DayPicker-Day--selected {
                position: relative;
                background: none;
                &:before {
                  content: "";
                  background: rgba(185, 193, 236, 0.6);
                  border-radius: 100px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 30px;
                  height: 30px;
                  margin: auto;
                  pointer-events: none;
                  z-index: -1;
                }
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
        &:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
            .DayPicker-Day--outside
          ):hover {
          position: relative;
          background: none;
          &:before {
            content: "";
            background-color: #f0f8ff;
            border-radius: 100px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
            margin: auto;
            pointer-events: none;
            z-index: -1;
          }
        }
      }
      &.force-show-calendar {
        .DayPicker {
          position: relative;
        }
      }
      .DayPicker-Footer {
        border-top: 2px solid #dddddd;
        padding: 14px 10px 0;
        text-align: center;
        margin-top: 15px;
        button {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.88px;
          line-height: 23px;
          color: #007a4c;
          &:focus {
            outline: none;
          }
        }
      }
      &.custom-date-picker {
        .DayPicker {
          margin-top: -10px;
        }
        &.disabled {
          .small-label {
            color: $gray-light-300;
          }
          .DayPickerInput {
            border: 1px solid #dbdffa;
            background: linear-gradient(0deg, #ebebeb, #ebebeb);
            pointer-events: none;
          }
        }
      }
      .datePicker-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background: rgba(255, 255, 255, 0.05);
      }
      &.relative-picker {
        .DayPicker {
          position: relative;
        }
      }
    }
    .calendar-legends {
      overflow: hidden;
      // ***
      &.hospital {
        padding-top: 10px;
      }
      .key-text {
        float: left;
        padding: 0px 22px 10px 9px;
        position: relative;
        color: #949494;
        @extend .secondaryFont;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 17px;
        background: transparent;
      }
      span {
        float: left;
        padding: 10px 20px 10px 24px;
        position: relative;
        color: #949494;
        @extend .secondaryFont;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 17px;
        background: transparent;
        &:before {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 12px; // ***
          position: absolute;
          top: -1px;
          left: 0;
          bottom: 0;
          margin: auto;
          background: #e6e9f7;
        }
        &.confirmed {
          &:before {
            background: $confirmedShift;
          }
        }
        &.requested {
          &:before {
            background: $requestedShift;
          }
        }
        &.posted {
          &:before {
            background: $postedShift;
          }
        }
        &.expired {
          &:before {
            background: $expiredShift;
          }
        }
        &.expired-post {
          &:before {
            background: #ece4f7;
          }
        }
        &.expires-soon {
          &:before {
            background: $primary-color;
          }
        }
      }
    }
  }
  .custom-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.26);
    background-color: rgba(110, 110, 110, 0.4);
    .modal-wrapper {
      padding: 30px 40px 40px;
      max-width: 752px;
      max-height: 90%;
      border-radius: 7px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.22);
      .head-wrap {
        padding-bottom: 20px;
        position: relative;
        h5 {
          color: $title-primary;
          @extend .primaryFont;
          font-size: 24px;
          line-height: 40px;
          font-weight: normal;
        }
        .close {
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          opacity: 1;
          background: url("../images/close.svg") no-repeat center center;
          &:hover {
            opacity: 0.8;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .body-wrap {
        overflow: auto;
      }
    }
  }
  .custom-loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    display: none;

    &.page-loader {
      z-index: 9999;
      background: rgba(0, 0, 0, 0.46);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: auto;
      width: auto;
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .custom-loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: custom-loader 900ms linear infinite;
  }
  @keyframes custom-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    z-index: 9;
  }

  @mixin accordian-parent-title($color, $line-height, $font-size, $font-family) {
    .accordion__button {
      font-family: $font-family;
      user-select: none;
      position: relative;
      cursor: pointer;
      background-color: #edf0fd;
      padding: 10px;
      border: 0;
      color: $color;
      font-size: $font-size;
      line-height: $line-height;
      &:focus {
        outline: none;
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: url("../images/expand_purple.png") center center no-repeat;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        @extend .transition;
        transform-style: preserve-3d;
      }
      &[aria-expanded="true"] {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  @mixin accordian-panel-date($font-family, $font-size, $font-weight, $line-height, $color) {
    display: block;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    cursor: pointer;
    font-family: $font-family;
    color: $color;
    position: relative;
    padding: 0 40px 0 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 18px;
      height: 18px;
    }
    &:before {
      background: url("../images/expand_grey.png") center center no-repeat;
      @extend .transition;
      transform-style: preserve-3d;
    }
    &.active {
      &::before {
        transform: rotateX(-180deg);
      }
    }
  }

  .accordion {
    overflow: auto;
    @extend .secondaryFont;
    .accordion__item {
      .accordion__button {
        user-select: none;
        &:focus {
          outline: none;
        }
      }
      .parent-title {
        @include accordian-parent-title(#7b87cc, 14px, 19px, "Open Sans");
      }
      .parent-title-v2 {
        @include accordian-parent-title(#3747ab, 28px, 20px, "Jost");
      }
      .parent-title-breakdown {
        .accordion__button {
          user-select: none;
          position: relative;
          cursor: pointer;
          background-color: rgba(246, 247, 252, 0.8);
          padding: 10px 10px 10px 24px;
          border: 0;
          color: #7b87cc;
          font-size: 14px;
          line-height: 19px;
          &:focus {
            outline: none;
          }
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            background: url("../images/expand_purple.png") center center no-repeat;
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            @extend .transition;
            transform-style: preserve-3d;
          }
          &[aria-expanded="true"] {
            font-weight: 600;
            &:after {
              transform: rotateX(-180deg);
            }
          }
        }
      }
      .accordion__panel {
        .name {
          padding: 12px 12px 0;
          overflow: hidden;
          label {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            @extend .secondaryFont;
            color: $text-secondary;
            display: block;
          }
          a {
            color: $primary-color;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 26px;
            @extend .primaryFont;
            float: left;
          }
        }
        // SIDEBAR nested accordion
        .info {
          //.accordion .accordion__item .accordion__panel .info
          @extend .tertiaryFont;
          .accordion__button[aria-expanded="true"] {
            user-select: none;
            font-weight: 600;
            span {
              &:before {
                transform: rotateX(-180deg);
              }
            }
          }
          & > div {
            padding: 10px 12px;
            border-bottom: 2px solid #f6f7fc;
            user-select: none;
            & .acc-sub-title {
              @extend .body-text;
              font-family: "Jost", sans-serif !important;
              color: $title-primary;
              margin-bottom: 8px;
            }
            &:focus {
              outline: none;
            }
            &.accordion__panel {
              overflow: hidden;
              background-color: rgba(250, 250, 250, 0.8);
              padding: 0;
              border-bottom: 2px solid #ededed;
              & > .info-details {
                padding: 10px 12px;
                &.expired {
                  background: rgb(252, 244, 244);
                }
              }
            }
            // &.accordion__button[aria-expanded="true"] {
            //   // ??? is this used
            //   span {
            //     &:before {
            //       transform: rotateX(-180deg);
            //     }
            //   }
            // }
            .date {
              @include accordian-panel-date("Jost", 16px, 600, 22px, $text-secondary);
            }
            .date-v2 {
              @include accordian-panel-date("Poppins", 16px, 500, 24px, $gray-light-400);
            }
            & > div {
              // .accordion .accordion__item .accordion__panel .info > div > div
              padding-top: 4px;
              position: relative;
              .contact-info {
                color: $text-ternary;
              }
              // p,
              span {
                font-size: 14px;
                font-weight: normal;
                line-height: 19px;
                @extend .secondaryFont;
                color: $text-secondary;
                strong {
                  font-weight: 600;
                }
                &.date {
                  font-size: 16px;
                  font-weight: 600;
                }
                &.payment {
                  margin-top: 30px;
                  color: #404040;
                  font-weight: 500;
                  text-align: center;
                  @extend .primaryFont;
                  strong {
                    color: #55ad8c;
                    font-weight: 500;
                  }
                }
              }
              // .link{
              //     position: absolute;
              //     right:24px;
              //     top:2px;
              //     font-size: 15px;
              //     font-weight: 500;
              //     @extend .primaryFont;
              //     pointer-events: none;
              // }
              .vets {
                list-style: none;
                margin: 0;
                padding: 30px 0 0;
                li {
                  overflow: hidden;
                  padding-top: 24px;
                  &:first-child {
                    padding: 0;
                  }
                  label {
                    color: #535353;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.75px;
                    line-height: 26px;
                    float: left;
                    width: calc(100% - 108px);
                    @extend .primaryFont;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  a {
                    float: right;
                    padding: 0 10px;
                    text-transform: uppercase;
                    min-width: 106px;
                    letter-spacing: 1px;
                    position: relative;
                    top: 4px;
                    @extend .primaryFont;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .amplify-section-body,
  .amplify-error-section {
    display: none !important;
  }
  .add-link {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.13px;
    line-height: 29px;
    cursor: pointer;
    color: $primary-color;
  }
  .data-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      width: 100%;
      li {
        display: table-row;
        color: $text-secondary;
        font-size: 16px;
        line-height: 22px;
        @extend .secondaryFont;
        & > div {
          display: table-cell;
          padding: 10px;
        }
        & > div:first-child {
          text-align: left;
          padding-left: 0;
        }
        & > div:last-child {
          width: 80px;
          padding-right: 0;
          text-align: center;
        }
        &.head {
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 26px;
          color: $label-primary;
        }
        a {
          background: url("../images/close-red.png") center center no-repeat;
          background-size: 100%;
          height: 18px;
          width: 18px;
          display: inline-block;
          position: relative;
          top: 3px;
          &:hover {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  .table-container {
    .table {
      thead th {
        border-bottom: 2px solid #dddddd;
        border-top: 0;
        vertical-align: middle;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 21px;
        color: $label-primary;
        font-weight: normal;
      }
      tbody {
        tr td {
          border: 0;
          @extend .secondaryFont;
          color: #535353;
          font-size: 16px;
          line-height: 22px;
          &.max-width-200 {
            max-width: 200px;
          }
          &.max-width-250 {
            max-width: 250px;
          }
          &.link-color-f-14 {
            color: #007a4c;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
          }
          a {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 26px;
            position: relative;
            cursor: pointer;
            @extend .primaryFont;
            &.legend {
              white-space: nowrap;
              &:before {
                content: "";
                position: absolute;
                left: -20px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 10px;
                width: 10px;
                border-radius: 10px;
                background: #c35f7b;
              }
            }
          }
        }
      }
      &.table-striped tbody {
        tr:nth-of-type(even) {
          background-color: #f2f3f7;
        }
        tr:nth-of-type(odd) {
          background-color: transparent;
        }
        tr.highlight {
          background-color: #fff;
        }
      }
      thead th,
      tbody td {
        &.pr-md {
          padding-right: 30px;
        }
      }
    }
  }
  .delete-icon-wrap {
    height: 20px;
    width: 20px;
  }
  .delete-row-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    background: url("../images/close-red.png") right center no-repeat;
    // background-size: 100%;
    background-size: contain;
    @include transition;
    &:hover {
      opacity: 0.8;
      @include transition;
    }
  }
  .credit-card-info {
    padding-right: 40px;
    position: relative;
    margin-top: 18px;
    .cardNumber {
      width: 240px;
    }
    .delete-row-icon {
      display: flex;
      align-self: flex-end;
      margin-left: 30px;
      margin-bottom: 10px;
    }
  }
  .vet-profile-pic {
    max-height: 400px;
  }
  .delete-cross-small {
    cursor: pointer;
    display: inline-block;
    background: url("../images/delete_photo.png") center center no-repeat;
    background-size: 100%;
    height: 18px;
    width: 18px;
    margin: 2px;
  }
  .delete-cross {
    cursor: pointer;
    background: url("../images/close-red.png") center center no-repeat;
    background-size: 100%;
    height: 18px;
    width: 18px;
    display: block;
    position: relative;
    top: 19px;
    margin: 0 auto;
    right: 0;
    left: 0;
    &:after {
      content: "";
      opacity: 0;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 1px;
      background: #82ceb1;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }

  .z-index-1 {
    z-index: 1;
  }

  .no-replacement-button {
    color: #ff453a;
    display: block;
    height: 42px;
    cursor: pointer;
  }

  .add-note::placeholder {
    font-style: italic;
  }

  .independent-contractor-body {
    text-align: justify;
    @media screen and (max-width: 440px) {
      text-align: left;
    }
  }

  .signature {
    height: 60px;
    overflow-y: auto;
  }
}
.align-center {
  text-align: center;
}
.eventBox {
  width: auto !important;
}

.notification-dropdown {
  position: absolute;
  left: auto;
  right: 27%;
  box-shadow: 0 2px 15px 0 rgb(185 185 185 / 35%);
  border-radius: 15px;
  border: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  margin-top: 8px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
}

.dot-menu {
  padding-left: 10px;
  margin-top: -5px;
  cursor: pointer;
  font-size: 18px;
}

.payment-pop-label {
  font-size: 15px !important;
  color: #535353 !important;
  letter-spacing: 1.5px !important;
}
.action-btn {
  border: none;
  background-color: transparent;
  color: #007a4c;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
}
.accept-btn {
  border: none;
  background-color: transparent;
  color: #007a4c;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
}

@keyframes downAnimation {
  0% {
    transform: translate(0, -20%);
  }
  33% {
    transform: translate(0, -14%);
  }
  66% {
    transform: translate(0, -7%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.shift-visible:hover {
  cursor: pointer;
}
.shift-visible {
  td:last-child {
    a {
      position: relative;
      &:before {
        content: "";
        width: 20px;
        height: 16px;
        position: absolute;
        top: 16px;
        right: -42px;
        background: url("../images/dropdown_arrow.svg") right center no-repeat;
        background-size: 100%;
        transform: rotateX(0);
      }
    }
  }
}
.shift-visible.shift-open {
  td:last-child {
    a {
      &:before {
        content: "";
        width: 20px;
        height: 16px;
        position: absolute;
        top: 16px;
        right: -42px;
        background: url("../images/dropdown_arrow.svg") right center no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
      }
    }
  }
}

.active-shift {
  background-color: beige !important;
}

.delete-this-shift {
  height: 29px;
  width: 150px;
  color: #c35f7b;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
}

.info-button {
  background: url("../images/info.svg") center top no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 26px;
  width: 26px;
  position: relative;
  top: 3px;
  cursor: pointer;
  &.size-20 {
    height: 20px;
    width: 20px;
    top: 5px;
  }
  &.size-15 {
    height: 15px;
    width: 15px;
    top: 3px;
  }
}

.info-button-solid {
  background: url("../images/info-solid.svg") center top no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 26px;
  width: 26px;
  position: relative;
  top: 3px;
  cursor: pointer;
  &.size-20 {
    height: 20px;
    width: 20px;
    top: 5px;
  }
  &.size-15 {
    height: 15px;
    width: 15px;
    top: 3px;
  }
}

.info-section {
  background: $well-bg;
  font-size: 12px;
  padding: 18px 12px;
  p:last-child {
    margin: 0 !important;
  }
}
.expand-arrow {
  width: 36px;
  height: 36px;
  background: url("../images/expand_purple.png") center center no-repeat;
  cursor: pointer;
  position: absolute;
  left: 7.2em;
  top: -4px;
  margin: auto;
  @extend .transition;
  transform-style: preserve-3d;
  &.selected {
    transform: rotateX(-180deg);
    // margin: 22px auto 0;
  }
}

.fixed-chevron-right {
  width: 30px;
  height: 48px;
  background: url("../images/chevron_right.png") center center no-repeat;
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: 0.33rem;
  margin: auto;
  @extend .transition;
  transform-style: preserve-3d;
  transform: scale(0.2);
}

.chevron-down {
  width: 30px;
  height: 48px;
  background: url("../images/chevron_down.png") center center no-repeat;
  cursor: pointer;
  position: absolute;
  top: -7px;
  right: 0.33rem;
  margin: auto;
  @extend .transition;
  transform-style: preserve-3d;
  transform: scale(0.2);
}

.radio-title-row {
  position: relative;
  padding-bottom: 15px;
}

.radio-info {
  background-color: rgba(221, 221, 221, 0.28);
  margin-left: 40px;
  margin-top: 4px;
  padding: 10px 14px;
  color: #535353;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.z-2 {
  z-index: 2;
}

.link-divider {
  color: $para-secondary;
  padding: 10px;
}

.link-group {
  a.selected {
    border-bottom: 1px solid $primary-color;
  }
}

.vet-list {
  .requested {
    @media (min-width: 768px) {
      div {
        width: 100%;
        text-align: right;
      }
    }
    span {
      width: 100px;
      display: block;
    }
    .btn.btn-primary.xs-btn {
      width: 100% !important;
      padding: 5px 15px !important;
      text-align: center !important;
      min-width: 100px !important;
      max-width: 130px !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
      font-size: 14px !important;
    }
  }
}

.vet-list {
  .expired {
    a,
    div.link-button {
      color: #fff !important;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.shift-boost-msg {
  h2 {
    margin-bottom: 15px;
  }
  h4 {
    font-size: 18px;
    line-height: 1.2;
  }
}

.editShift-line-height {
  line-height: 1.5;
}

.h-auto {
  min-height: auto !important;
}

.inactive {
  pointer-events: none;
  opacity: 0.35;
}

.contractor-request-modal {
  background-color: #f5ebae;
}

.listButtonWrap {
  margin-bottom: 10px;
  span {
    min-width: 120px;
    display: inline-block;
  }
}

.vet-request-button {
  padding: 5px 15px !important;
  text-align: center !important;
  min-width: 100px !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
}

.previous-shifts-wrapper {
  @media all and (min-width: 320px) and (max-width: 767px) {
    padding: 15px 15px 0px 15px !important;
  }
}

.mobile-mt-24 {
  @media all and (min-width: 320px) and (max-width: 767px) {
    margin-top: 24px;
  }
}

.min-w {
  min-width: max-content !important;
}

.how-to-roo {
  font-size: 16px;
  .inner-container {
    overflow: initial !important;
    padding: 107px 0 !important;
    &:after {
      height: 100% !important;
    }
  }
}

.how-to-roo h6,
h2 {
  color: $blue;
}

.how-to-roo .div-border {
  border: 1px solid #7b87cc;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  margin-top: 30px;
  ul {
    padding-left: 20px;
  }
  span {
    color: $primary-color;
  }
}

.how-to-roo ul li {
  line-height: 22px;
  font-weight: normal;
}

.how-to-roo .best-practice {
  max-width: 800px;
  margin: 0 auto;
}

.best-practice-heading {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.help-library {
  max-width: 800px;
  margin: 160px auto;
  width: 100%;
  box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.09);
  padding: 60px 0;
  text-align: center;
  .accordion__button {
    color: #535353 !important;
    font-weight: 500 !important;
    // background-color: #fff !important;
    text-transform: none !important;
    font-family: Poppins, sans-serif;
    font-size: 16px !important;
    max-width: 700px;
    margin: 0 auto;
    padding: 14px !important;
    &:hover {
      background-color: #ebecf5 !important;
    }
  }

  > p {
    max-width: 628px;
    width: 100%;
    margin: 0 auto 30px;
  }
}

.how-to-roo .padding-top {
  padding-top: 60px;
}

.how-to-roo .back-button {
  z-index: 1;
  position: relative;
  margin-left: 84px;
}

.howtoroo-banner {
  background-color: $banner-bg;
  margin-bottom: 0;
  text-align: center;
}
.banner-cat {
  background-image: url("../images/banner_cat.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 180px;
  width: 230px;
}

.banner-dog {
  background-image: url("../images/banner_dog.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 180px;
  width: 230px;
}

.howtoroo-title {
  padding: 0;
  color: $title-primary;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  line-height: 59px;
}

.howtoroo-tagline {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: $text-secondary;
  font-size: 16px;
  line-height: 21px;
  max-width: 560px;
}

.accordion-message {
  font-family: "Poppins", sans-serif;
  max-width: 700px;
  margin: 0 auto;
}

.header-top-menu {
  .is-active {
    border-bottom: 2px solid;
  }
}

.is-active {
  cursor: default !important;

  &:hover {
    &:after {
      opacity: 0 !important;
    }
  }
}

form {
  .user-date-range {
    .previous {
      background: url("../images/arrow_left.png") center center no-repeat;
      height: 44px;
      width: 44px;
      cursor: pointer;
    }
    .next {
      background: url("../images/arrow_right.png") center center no-repeat;
      height: 44px;
      width: 44px;
      cursor: pointer;
    }
    .inactive {
      opacity: 0.35;
      cursor: default;
      pointer-events: none;
    }
    input {
      width: 120px;
      height: 40px !important;
      @media screen and (max-width: 767px) {
        font-size: 14px !important;
        width: 106px !important;
      }
    }
    p.dash {
      position: relative;
      top: 10px;
      color: #9e9fa0;
    }
  }
}

.history-header {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.history-search-input {
  @media screen and (max-width: 767px) {
    width: 0px !important;
  }
}

.width-105 {
  width: 105px !important;
}

.icon-wrap-17 {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-size: contain;
  vertical-align: middle;
}
.icon-wrap-20 {
  display: inline-block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-size: contain;
  vertical-align: middle;
}
.icon-blue {
  width: 100%;
  height: 100%;
  background: url("../images/alert_blue.svg") center center no-repeat;
}

.icon-wrap-scalable-btn {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-size: contain;
  vertical-align: middle;
  .up-icon {
    width: 100%;
    height: 100%;
    background: url("../images/arrow_circle_up-dim.svg") center center no-repeat;
    &.active {
      cursor: pointer;
      background: url("../images/arrow_circle_up.svg") center center no-repeat;
    }
  }
  .down-icon {
    width: 100%;
    height: 100%;
    background: url("../images/arrow_circle_down-dim.svg") center center no-repeat;
    &.active {
      cursor: pointer;
      background: url("../images/arrow_circle_down.svg") center center no-repeat;
    }
  }
  .visibility-icon {
    width: 100%;
    height: 100%;
    background: url("../images/visibility-dim.svg") center center no-repeat;
    &.active {
      cursor: pointer;
      background: url("../images/visibility.svg") center center no-repeat;
    }
  }
}

.letter-spacing-1 {
  letter-spacing: 0.1px !important;
}

.paddingtop-100 {
  padding-top: 100px !important;
}

.field-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.field-disabled-normal {
  pointer-events: none;
}

.line-height-normal {
  line-height: normal;
}
.line-height-normal-plus {
  line-height: 1.6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.search-icon {
  background: $primary-color url("../images/search-icon.png") center center no-repeat;
  color: $primary-color;
}

body {
  #root {
    .vet-dashboard {
      .custom-check.open-sans [type="radio"]:not(:checked) + label,
      .custom-check.open-sans [type="radio"]:checked + label {
        @media (max-width: 767px) {
          font-size: 12px;
          padding-left: 32px;
        }
      }

      .search-result-wrapper {
        .shift-box-grid {
          &.full-width-grid {
            .modal-p-wrap {
              .col-md-6 {
                width: 50% !important;
                flex: 0 0 50% !important;
                max-width: 50% !important;
                @media (max-width: 767px) {
                  width: 100% !important;
                  flex: 0 0 100% !important;
                  max-width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.event-title {
  @extend .label-text;
  color: $dark-gray;
  margin: 16px 0px;
}

.event-cancel {
  cursor: pointer;
  font: 16px Poppins, sans-serif;
  font-weight: 600;
  color: #007a4c;
  position: relative;
  text-align: center;
}
.event-add-page {
  top: -76px;
}

.pb-28 {
  padding-bottom: 28px;
}

.boost-all {
  position: absolute;
  top: 135px;
  display: flex;
}

.boost-mobile {
  position: absolute;
  top: 250px;
  display: flex;
  margin-left: 30px;
}

.arrow-90-tran {
  transform: rotate(90deg);
}

.view-profile-button-v2 {
  @extend .body-text;
  color: $green-dark !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.view-profile-button-v2-pending {
  @extend .body-text;
  color: $gray-light-300 !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.not-allowed {
  cursor: not-allowed !important;
}
.white-svg {
  filter: invert(99%) sepia(4%) saturate(2%) hue-rotate(168deg) brightness(115%) contrast(100%);
}

.error-message {
  color: #ed4545;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.overlay-active::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 89; /* 1 lower than header */
  pointer-events: none;
}

/* Ensure the body content doesn't scroll when overlay is active */
.overlay-active {
  overflow: hidden;
  z-index: 999;
}

.highlight {
  position: relative;
  z-index: 1001;
  pointer-events: auto;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 5px;
  border: 5px solid white;
}

.custom-popover .ant-popover-arrow::before {
  background-color: var(--grayWhite);
}
